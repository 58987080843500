html {
    width: 100%;
    width: 100vw;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #fa8650!important;
    --bs-btn-border-color: #fa8650!important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #fa8650!important;
    --bs-btn-hover-border-color: #fa8650!important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #fa8650!important;
    --bs-btn-active-border-color: #fa8650!important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.backgroundImg img{
    min-height: 100%!important;
    position: absolute;
    opacity: 0.5;
    width: 100%!important;
    max-width: 100%!important;
    z-index: -5;
}

.carousel-inner {
    padding-top: 3rem;
}

.carousel-caption {
    top: -7%;
    bottom: auto;
    font-weight: 900;
    text-decoration: underline;
  }

.carousel-control-prev-icon {
    position: absolute;
    left: 0vw;
}

.carousel-control-next-icon {
    position: absolute;
    right: 0vw;
}

.Contact {
    position: relative;
}

.ContentWrapper {
    padding-bottom: 265px;
    max-width: 100%;
}

.cookie-consent-modal {
    background-color: rgba(233, 224, 224, 0.93);
    font-size: 1rem;
    position: sticky;
    top: 80vh;
    left: 0%;
    width: 100%;
    height: 20vh;
    z-index: 100;
}

.Feature {
    margin-top: 5vh;
    position: relative;
    width: 60%;
}

.Feature p.lead {
    font-size: 1.3rem!important;
}

.Feature p, li, dt, dd {
    font-size: 1.2rem;
    line-height: 180%;
}

.FeatureContainer {
    position: relative;
    justify-content: center;
    margin-top: 5vh;
    width: 75%!important;
    z-index: 3;
}

.FeatureContent {
    max-width: 100%;
    
}

.featureImageContainer img {
    max-width:100%;
    height:auto;
    max-height:80vh;
}

.form-control {
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
}

.Footer {
    background-color: #155e60;
    width:100vw;
    height:265px;
    position: absolute;
    bottom:0;
    left:0;
    color: #D8D8D8;
    text-decoration: none;
    z-index: 5;
}

.FooterLeft a {
    color: #D8D8D8;
    text-decoration: none;
    margin-bottom: 5px;
}

.FooterLinks {
    position: relative;
    left: 15vw;
}

.Home {
    position: relative;
    height: 100vh;
}

.HomeShareButtons a {
    color: #e45f21!important;;
}

.App {
    position: relative;
    min-height: 100vh;
}

.entryText {
    font-size: 1.5rem!important;
    font-weight: 400!important;
}

.Header {
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    width: 99vw;
    z-index: 1;
}

.headerContainer {
    position: absolute;
    background-color: rgba(252, 223, 196, 0.92);
    width: 100%;
    margin: auto;
    font-size: 1.25rem;
    font-weight: 400;
    
}

.headerContainer p{
    font-size: 1.4rem;
    font-weight: 500;
}

.headerContainerMobile {
    position: absolute;
    top: 5%;
    background-color: rgba(252, 223, 196, 0.92);
    width: 100%;
    margin: auto;
    font-size: 1.25rem;
    font-weight: 400;
}

.headerContainerMobile .headerList {
    display: none;
}

.headerList {
    padding-left: 30vw;
}

.languageButtonContainer {
    position: absolute;
    top: 10px;
    right: 5vw;
    z-index: 10;
}

.nav a {
    color: #e45f21!important;
}

.Navbar {
    position: relative;
    z-index: 1;
}

.NavItems .nav-item {
    align-self: center;
    text-wrap: nowrap;
}

.NavItems a {
    color: #e45f21!important;
}

.pauseButton {
    height: 20px;
    width: 20px;
    font-size: 12px!important;
    position: absolute;
    right: 1vw;
    top: calc(100% - 3vh);
    z-index: 2;
}

.pictureAndheaderContainerMobile{
    position: relative;
    top: 0%;
    left: 0%;
}

.pictureAndheaderContainerMobile .Footer {
    position: relative;
    align-self: center;
}

.pictureAndheaderSubContainer{
    position: relative;
    height: 100vh;
}

.pictureAndheaderSubContainer img {
    max-width: 100vw;
    max-height: 100vh;
}

.ScrollButton {
    position: absolute;
    bottom: 96vh;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb 2s infinite;
    animation: sdb 2s infinite;
    box-sizing: border-box;
}

.ScrollButton:hover {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
}

.SlidePicture {
    position: absolute;
    bottom: 0%;
}

.textBackground {
    background-color: rgba(255, 255, 255, 0.8);
}

.videoAndHeaderContainer {
    position: fixed;
    height: 80vh!important;
    width: 80vw!important;
    top: 10vh;
}

.videoAndHeaderSubContainer {
    transition: opacity 0.7s linear;
}

.videoContainer {
    position: absolute;
    height: 100%;
    width: 100%;
}

.videoRow {
    width: 70vw;
    height: auto;
    align-items: center;
    top: 10vh;
}

.videoRow video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

@media (orientation: landscape) {
    .entryHeaderContainer {
        justify-content: start!important;
    }
    .headerContainerMobile {
        bottom: 0%;
        display: flex;
        flex-direction: column;
        left: -15%;
        top: 0%;
        justify-content: center;
        width: 50vw;
    }
    .SlidePicture {
        right: -15%;

    }
}

@media (max-width: 1400px) {
    .carousel-caption {
        top: -9%;
      }
}

@media (max-width: 1000px) {
    .ContentWrapper {
        padding-bottom: 465px;
    }
    .Feature {
        position: relative;
        left: 0vw;
    }
    .Footer {
        width:100vw;
        height:auto;
    }
    .FooterLinks {
        position: relative;
        left: 11.5vw;
    }
    .carousel-caption {
        top: -15%;
      }
    .carousel-caption .lead {
        font-size: 1.1em;
    }
}

@media (max-width: 768px) {
    .carousel-caption {
        top: -25%;
      }
    .carousel-inner {
        padding-top: 4rem;
    }
    
}

@media (max-width: 500px) {
    html {
        max-width: 100%;
    }
    .cookie-consent-modal{
        top: 70vh;
    }
    .ContentWrapper {
        padding-bottom: 2vh;
    }
    .CopyrightText{
        font-size: 0.8rem;
    }
    .HomeShareButtons{
        font-size: 0.8rem;
    }
    .languageButtonContainer button{
        padding: 2px;
        font-size: 0.7rem;
    }
    .nav{
        align-self: end;
    }
    .Navbar{
        display: flex;
        height: inherit;
        margin-top: 10px;
    }
    .NavLogo {
        width: 70px;
    }
    .entryHeader {
        font-size: 2rem!important;
    }
    
    .entryText {
        font-size: 1.25rem!important;
    }
    .Feature {
        width: 100%;
    }
    .Feature p, li {
        font-size: 1.2rem;
        line-height: 140%;
    }
    .FeatureContainer {
        width: 90%!important;
    }
    .featureImageContainer img {
        max-width:100%;
        height:auto;
        max-height:80vh;
    }
    .FeatureContainer .nav{
        justify-content: space-between;
    }
    .FeatureContainer .nav a {
        font-size: 0.9rem!important;
    }
    .FooterLinks {
        position: relative;
        left: 5vw;
    }
    .FooterLinks{
        padding-left: 20%;
    }
    .FooterLogo {
        width: 80%;
    }
    .Header {
        height: 10vh;
    }
    .headerContainer h1{
        font-size: 1.5rem;
    }
    .headerContainer p{
        font-size: 1.0rem;
        margin-bottom: 0.5rem;
    }
    .headerList {
        font-size: 0.9rem;
        padding-left: 2rem;
        line-height: 120%;
        margin-bottom: 0.5rem;
        display: none;
    }
    .carousel-caption {
        top: -25%;
      }
    .carousel-inner {
        padding-top: 4rem;
    }
}
@media (max-width: 400px) {
    .NavLogo {
        width: 40px;
    }
    .FooterLinks {
        position: relative;
        left: 0vw;
    }
    .carousel-caption {
        top: -30%;
      }
    .carousel-caption .lead {
        font-size: 1em;
    }
    .nav-link {
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
    }
}

@media (max-width: 350px) {
    .small-screen-padding {
        padding-top: 3rem;
    }
    .carousel-caption {
        top: -35%;
      }
    .carousel-caption .lead {
        font-size: 0.9em;
    }
}

@-webkit-keyframes sdb {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
    }
    20% {
      -webkit-transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
    }
  }
@keyframes sdb {
    0% {
      transform: rotate(-45deg) translate(0, 0);
    }
    20% {
      transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      transform: rotate(-45deg) translate(0, 0);
    }
  }

@import "~bootstrap/scss/bootstrap";